import * as React from "react"
import "@iconscout/unicons/css/line.css";
import "normalize.css";
import "./global.scss";
import "./index.scss";

const IndexPage = () => {
  return [
    (<main key="main">
      <title>Bucketlist Babes - The Terra Generative NFT</title>
      <div className="overlay"></div>
      <div className="content">
          <h1 className="content__header">Bucketlist Babes</h1>
          <h3 className="content__sub-header">Under Construction</h3>
          <div className="content__social-media">
              <a className="social-media__link link--discord" href="https://discord.gg/ntzjks7bRA" target="_blank"><i className="uil uil-discord"></i></a>
              <a className="social-media__link link--twitter" href="https://twitter.com/BucketListbaes" target="_blank"><i className="uil uil-twitter"></i></a>
          </div>
      </div>
    </main>),
    <footer key="footer" className="footer">&copy; 2021 Bucketlist Babes</footer>
  ]
}

export default IndexPage
